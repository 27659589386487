import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../css/sections/HeroSection.css';
import timelineImage from '../../images/timeline.png'; 

export const HeroSection2: React.FC = () => {
  const navigate = useNavigate();

  return (
  <div className="section-section">
    <div className="section-container" style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <img 
        src={timelineImage} 
        alt="Timeline" 
        className="timeline-image" 
        style={{
          transform: 'scale(1)',
          maxWidth: '100%'
        }}
      />
    </div>
  </div>
  );
};
